<template>
  <div class="px-4 md:px-16">
    <section class="md:max-w-[1560px] mx-auto">
      <div
        class="bg-white py-10 flex w-full items-center flex-col rounded-t-lg lg:py-12"
      >
        <div>
          <inline-svg
            style="transform: scale(2.4) translateY(10px)"
            class="lg:hidden"
            :src="require('@/assets/upd16082024/bem-perto-do-seu-amanha.svg')"
            title="BemPerto Do Seu Amanhã"
          />
          <inline-svg
            style="transform: scale(1.6)"
            class="hidden lg:block"
            :src="require('@/assets/upd16082024/bemperto.svg')"
            title="BemPerto Do Seu Amanhã"
          />
        </div>
        <div
          class="text-center px-8 text-[#727272] lg:text-xl pb-6 lg:px-20 xl:px-48"
        >
          <h1 class="text-[#0B5E67] font-bold mb-8">Programa BemPerto®</h1>
          <p class="mb-3">
            O programa de suporte exclusivo para os pacientes Amgen® tem como
            objetivo oferecer todo o apoio para proporcionar uma melhor
            experiência durante o tratamento com nossos medicamentos. Aos
            pacientes, médicos e cuidadores, nosso programa oferece benefícios,
            informações e orientações sobre temas diversos relacionados a
            patologias e tratamentos dos programas participantes.
          </p>
        </div>
        <ul
          class="flex flex-col justify-center items-center lg:flex-row lg:mt-6"
        >
          <li class="mb-4 lg:mr-12">
            <a
              class="rounded-full py-8 w-[276px] shadow-icone flex flex-col items-center text-[10px] text-[#0B5E67] italic lg:rounded-3xl"
              target="_blank"
              href="https://programabemperto.com.br/"
            >
              <inline-svg
                class="mb-1"
                :src="require('@/assets/novo/computer.svg')"
                title="Computador"
              />
              <span class="font-bold text-xl">ACESSE</span>
              <span class="text-sm">https://programabemperto.com.br/</span>
            </a>
          </li>
          <li class="mb-4">
            <!-- <a
              class="rounded-full py-6 w-[276px] shadow-icone flex flex-col items-center text-[18px] text-[#0B5E67] italic lg:rounded-3xl"
              target="_blank"
              href="tel:+5508002640001"
            >
              <inline-svg
                class="mb-1"
                :src="require('@/assets/novo/call.svg')"
                title="Chamada"
              />
              <span class="font-bold text-xl">LIGUE</span>
              0800 264 0001
            </a> -->
          </li>
        </ul>
      </div>
    </section>
    <section class="mb-14 -mt-4 md:-mt-7 lg:-mt-8">
      <BigButton
        class="flex justify-center w-full px-6 md:max-w-[432px] md:mx-auto"
      />
    </section>
  </div>
</template>

<script>
import BigButton from "@/components/molecules/BigButton.vue";

export default {
  name: "BemPertoDoSeuAmanha",
  components: { BigButton },
};
</script>
